import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function A100() {
  const renderContent = data => {
    const sensor = {
      title: "A100",
      imageHeader: data.a100Header,
      imageHeaderMobile: data.a100HeaderMobile,
      objectPosition: "center",
      description:
        "Discover a radically new way to secure your spaces. Rhombus’ audio gateway provides synchronized audio with video, two-way audio with broadcasting, glass break detection, and more.",
      image: data.a100Dims,
      pdf: "/files/specs/A100.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "118mm (4.64in) x 118mm (4.64in) x 50.8mm (2in)",
        },
        {
          title: "Format",
          text: "WebM (OPUS), MP4 (AAC)",
        },
        {
          title: "Ethernet",
          text: "10/100 Mbps, MDI/MDX Auto Sensing",
        },
        {
          title: "Power-Over-Ethernet",
          text: "Standard 802.3af for one-cable installation",
        },
        {
          title: "Power Consumption",
          text: "Max 13 watts",
        },
        {
          title: "Onboard Storage",
          text: "Included 64GB",
        },
        {
          title: "Internal Microphone",
          text: "Omni-Directional (94dB SPL @ 1KHz / 100Hz – 10,000Hz)",
        },
      ],
      column2: [
        {
          title: "Internal Speaker",
          text: "82.5dB +/- 3dB",
        },
        {
          title: "External Audio",
          text: "3.5mm microphone input jack; 3.5mm speaker output jack",
        },
        {
          title: "Wireless",
          text: "Bluetooth",
        },
        {
          title: "Baseline, steady state",
          text: "2 - 10 Kbps",
        },
        {
          title: "Live Streaming",
          text: "52 Kbps",
        },
        {
          title: "Operating Temperature",
          text: ["10° to 50° C", "50° to 122° F"],
        },
        {
          title: "CE, FCC, NDAA, TAA Compliant",
        },
        {
          title: "2-Year Warranty Included",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>
            Rhombus A100 Audio Gateway - Video Surveillance with Audio
          </title>
          <meta
            name="description"
            content="Smart two-way audio security for commercial businesses and enterprises. Learn about the A100 audio gateway's intelligent audio analytics and more."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/a100_door.png"
          />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      a100Header: file(
        relativePath: { eq: "components/sensors/img/a100-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      a100HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/a100-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      a100Dims: file(
        relativePath: { eq: "components/sensors/img/a100-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
